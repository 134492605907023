<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search milestones"
          type="text"
        />
        <b-button
          v-b-modal.addMilestone
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Milestone
        </b-button>
      </b-form-group>
    </div>
    <b-overlay
      :show="this.$apollo.loading || mutationLoading"
      spinner-variant="primary"
    ><!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="milestones"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Action -->
          <span
            v-if="props.column.field === 'action'"
          >
            <b-button
              size="sm"
              variant="outline-primary"
              class="text-nowrap m-25"
              @click="selectedRow=props.row.originalIndex; $bvModal.show('mentorModal')"
            >
              Assign Mentor
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="text-nowrap m-25"
              @click="selectedRow=props.row.originalIndex; $bvModal.show('partnerModal')"
            >
              Assign Partner
            </b-button>
          </span>
          <span
            v-else-if="props.column.field === 'title'"
            class="d-flex justify-content-between align-items-start"
          >
            <span><b-badge
              v-b-tooltip="'Milestone Number'"
              class="mr-50 mb-25"
              variant="primary"
              v-text="props.row.stage_number"
            />{{ props.formattedRow[props.column.field] }}</span>
            <b-button
              size="sm"
              variant="link"
              :to="{name: 'startups-milestone-stages', params: {
                mid: props.row.id, id: $route.params.id
              }}"
              class="text-nowrap"
            >View Stages</b-button>
          </span>
          <span
            v-else-if="props.column.label === 'Status'"
            class="d-flex justify-content-between align-items-center"
          >
            <b-badge
              v-if="props.formattedRow[props.column.field]"
              :variant="statusVariant( props.formattedRow[props.column.field])"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
            <b-button
              size="sm"
              variant="link"
              @click="selectedRow=props.row.originalIndex; $bvModal.show('recommendationModal')"
            >Update</b-button>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
    <b-modal
      id="addMilestone"
      no-close-on-backdrop
      ok-only
      ok-title="Add"
      title="Add Milestone"
      @ok="addMilestone"
    >
      <b-form-group
        label="Milestone Title"
      >
        <b-form-input
          v-model="milestoneTitle"
          placeholder="Milestone Title"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      v-if="milestones[selectedRow] && milestones[selectedRow].programs_progressrecordtables[0]"
      id="recommendationModal"
      no-close-on-backdrop
      ok-only
      ok-title="Update"
      title="Recommendations"
      size="md"
      @ok="updateStatus"
    >
      <b-form-group
        label="Recommendations"
      >
        <b-form-input
          v-model="milestones[selectedRow].programs_progressrecordtables[0].recommendations"
          placeholder="Your recommendation for startup"
        />
      </b-form-group>
      <b-form-group
        label="Status"
      >
        <v-select
          v-model="milestones[selectedRow].programs_progressrecordtables[0].status"
          :options="['Excellent', 'Good', 'Satisfactory', 'Unsatisfactory', 'Incomplete']"
          placeholder="Select from list"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      v-if="milestones[selectedRow]"
      id="mentorModal"
      no-close-on-backdrop
      ok-only
      ok-title="Update"
      title="Assign Mentor"
      @ok="assignMentor"
    >
      <b-form-group
        label="Select Mentor"
      >
        <treeselect
          v-model="mentor_association_id"
          :options="mentors"
          :disable-branch-nodes="true"
          :show-count="true"
          placeholder="Type to Search"
        />
      </b-form-group>
      <b-table
        class="table-striped"
        :items="milestones[selectedRow].mentors"
        :fields="['name', {key: 'status', label: 'Status', tdClass:'text-capitalize'}, {key: 'assignee_status', label: 'Assignee Status', tdClass:'text-capitalize'}]"
      />
    </b-modal>
    <b-modal
      v-if="milestones[selectedRow]"
      id="partnerModal"
      no-close-on-backdrop
      ok-only
      ok-title="Update"
      title="Assign Partner"
      size="md"
      @ok="assignPartner"
    >
      <b-form-group
        label="Select Partner"
      >
        <treeselect
          v-model="partner_association_id"
          :options="partners"
          :disable-branch-nodes="true"
          :show-count="true"
          placeholder="Type to Search"
        />
      </b-form-group>
      <b-table
        class="table-striped"
        :items="milestones[selectedRow].partners"
        :fields="['name', {key: 'status', label: 'Status', tdClass:'text-capitalize'}, {key: 'assignee_status', label: 'Assignee Status', tdClass:'text-capitalize'}]"
      />
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge, BButton, BFormGroup, BFormInput, BFormSelect, BPagination, BTable,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Treeselect from '@riophae/vue-treeselect'
import { getUserData } from '@/auth/utils'
// import gql from 'graphql-tag'

export default {
  components: {
    vSelect,
    BBadge,
    Treeselect,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BTable,
  },
  data() {
    return {
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Milestone',
          field: 'title',
        },
        {
          label: 'Recommendation',
          field(row) {
            return row.programs_progressrecordtables[0]?.recommendations
          },
        },
        {
          label: 'Status',
          field(row) {
            return row.programs_progressrecordtables[0]?.status
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      milestones: [],
      mentor_association_id: null,
      mentors: [{
        id: 'in-house-mentors',
        label: 'In-House',
        children: [],
      },
      {
        id: 'network-mentors',
        label: 'Network',
        children: [],
      }],
      partner_association_id: null,
      partners: [{
        id: 'in-house-partners',
        label: 'In-House',
        children: [],
      },
      {
        id: 'network-partners',
        label: 'Network',
        children: [],
      }],
      milestoneTitle: null,
      mutationLoading: false,
    }
  },
  computed: {

    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Excellent: 'light-success',
        Good: 'light-success',
        Satisfactory: 'light-warning',
        Unsatisfactory: 'light-danger',
        Incomplete: 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {
    addMilestone() {
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation {
              insert_programs_progressstagetable_one(object: {title: "${this.milestoneTitle}", startup_association_id: ${this.$route.params.id}, stage_number: "${this.milestones.length + 1}"}) {
                id
              }
            }`,
          update: () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Milestone added successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$apollo.queries.milestones.refetch()
            this.mutationLoading = false
          },
        },
      )
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding milestone',
              icon: 'XIcon',
              variant: 'danger',
              message: error.message,
            },
          })
          this.mutationLoading = false
        })
      this.milestoneTitle = null
    },
    updateStatus() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($object: programs_progressrecordtable_insert_input!) {
          insert_programs_progressrecordtable_one(object: $object, on_conflict: {constraint: programs_progressrecordtable_pkey, update_columns: [status, recommendations]}) {
            id
          }
        }`,
        variables: {
          object: this.milestones[this.selectedRow].programs_progressrecordtables[0],
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
        },
      })
    },
    assignMentor() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($object: milestone_assignment_insert_input!) {
          insert_milestone_assignment_one(object: $object) {
            milestone_assignment_id
          }
        }`,
        variables: {
          object: {
            status: 'assigned',
            milestone_id: this.milestones[this.selectedRow].id,
            assignee_id: this.mentor_association_id,
            assignee_status: 'unchecked',
          },
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.milestones.refetch()
          this.mutationLoading = false
        },
      })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: error,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })

          this.mutationLoading = false
        })

      this.mentor_association_id = null
    },
    assignPartner() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($object: milestone_assignment_insert_input!) {
          insert_milestone_assignment_one(object: $object) {
            milestone_assignment_id
          }
        }`,
        variables: {
          object: {
            status: 'assigned',
            milestone_id: this.milestones[this.selectedRow].id,
            assignee_id: this.partner_association_id,
            assignee_status: 'unchecked',
          },
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.milestones.refetch()
          this.mutationLoading = false
        },
      })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: error,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.mutationLoading = false
        })
      this.partner_association_id = null
    },
  },
  apollo: {
    milestones: {
      query() {
        return gql`
        query startup_milestones{
          programs_progressstagetable(where: {startup_association_id: {_eq: ${this.$route.params.id} }}){
            id
            stage_number
            title
            programs_progressrecordtables{
              progress_stage_id
              recommendations
              status
              id
            }
            milestone_assignments {
              assignee_id
              status
              assignee_status
              users_associationtable {
                role
                users_customuser {
                  full_name
                }
                users_organizationtable {
                  title
                }
              }
            }
          }
        }`
      },
      update(data) {
        data.programs_progressstagetable.forEach(e => {
          if (e.programs_progressrecordtables.length < 1) {
            e.programs_progressrecordtables.push({
              recommendations: '',
              status: '',
              participant_id: this.$route.params.aid,
              progress_stage_id: e.id,
            })
          }
          e.mentors = []
          e.partners = []
          e.milestone_assignments.forEach(e2 => {
            if (e2.users_associationtable) {
              if (e2.users_associationtable.role === 'mentor') {
                e.mentors.push({
                  id: e2.assignee_id,
                  name: e2.users_associationtable.users_customuser.full_name,
                  status: e2.status,
                  assignee_status: e2.assignee_status,
                })
              } else if (e2.users_associationtable.role === 'partner') {
                e.partners.push(
                  {
                    id: e2.assignee_id,
                    name: e2.users_associationtable.users_organizationtable.title,
                    status: e2.status,
                    assignee_status: e2.assignee_status,
                  },
                )
              }
            }
          })
        })
        return data.programs_progressstagetable
      },
    },
    mentors: {
      query: gql`
        query mentors($incubator_id: Int!) {
            users_associationtable(where: {incubator_id: {_eq: $incubator_id}, role: {_in: ["mentor"]}}) {
            id
            status
            users_customuser {
              full_name
            }
          }
        }`,
      variables: {
        incubator_id: getUserData()
          .associatedOrgDetails
          .filter(e => e.role === 'superadmin')[0].organization_id,
      },
      update(data) {
        const result = this.mentors

        data.users_associationtable
          .forEach(mentor => {
            if (mentor.status.toLowerCase() === 'accept') {
              result[0].children.push({
                id: mentor.id,
                label: mentor.users_customuser.full_name,
              })
            } else {
              result[1].children.push({
                id: mentor.id,
                label: mentor.users_customuser.full_name,
              })
            }
          })

        return result
      },
    },
    partners: {
      query: gql`
        query partners($incubator_id: Int!) {
            users_associationtable(where: {incubator_id: {_eq: $incubator_id}, role: {_in: ["partner"]}}) {
            id
            status
            users_organizationtable {
              title
            }
          }
        }`,
      variables: {
        incubator_id: getUserData()
          .associatedOrgDetails
          .filter(e => e.role === 'superadmin')[0].organization_id,
      },
      update(data) {
        const result = this.partners

        data.users_associationtable
          .forEach(partner => {
            if (partner.status.toLowerCase() === 'accept') {
              result[0].children.push({
                id: partner.id,
                label: partner.users_organizationtable.title,
              })
            } else {
              result[1].children.push({
                id: partner.id,
                label: partner.users_organizationtable.title,
              })
            }
          })
        return result
      },
    },
  },
}
</script>
