<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card class="text-center">
          <b-tabs
            v-model="activeTab"
            nav-class="mb-3"
            class="h-scroll-tabs"
            pills
            @input="updateHash"
          >
            <b-tab
              active
              class="text-left"
              href="About"
              title="About"
              lazy
            >
              <About />
            </b-tab>
            <!--            <b-tab-->
            <!--              class="text-left"-->
            <!--              href="Agreements"-->
            <!--              title="Agreements"-->
            <!--              lazy-->
            <!--            >-->
            <!--              $Lorem-->
            <!--            </b-tab>-->
            <b-tab
              class="text-left"
              href="EMeet"
              title="E-Meet"
              lazy
            >
              <EMeet />
            </b-tab>
            <b-tab
              class="text-left"
              href="Programs"
              title="Programs"
              lazy
            >
              <Programs />
            </b-tab>
            <b-tab
              class="text-left"
              href="Milestones"
              title="Milestones"
              lazy
            >
              <Milestones />
            </b-tab>
            <b-tab
              class="text-left"
              href="Requests"
              title="Requests"
              lazy
            >
              <Requests />
            </b-tab>
            <b-tab
              class="text-left"
              href="Partners"
              title="Partners"
              lazy
            >
              <Partners />
            </b-tab>
            <b-tab
              class="text-left"
              href="Mentors"
              title="Mentors"
              lazy
            >
              <Mentors />
            </b-tab>
            <b-tab
              class="text-left"
              href="Growths"
              title="Growths"
              lazy
            >
              <Growth />
            </b-tab>
            <b-tab
              class="text-left"
              href="Feedbacks"
              title="Feedbacks"
              lazy
            >
              <Feedback />
            </b-tab>
            <b-tab
              class="text-left"
              href="Documents"
              title="Documents"
              lazy
            >
              <Document />
            </b-tab>
            <!--            <b-tab-->
            <!--              class="text-left"-->
            <!--              href="Campaigns"-->
            <!--              title="Campaigns"-->
            <!--              lazy-->
            <!--            >-->
            <!--              $Lorem-->
            <!--            </b-tab>-->
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'

import Milestones from '@/views/incubator-portal/startups/pages/startup-manager/Milestones.vue'
import Requests from '@/views/incubator-portal/startups/pages/startup-manager/Requests.vue'
import Programs from '@/views/incubator-portal/startups/pages/startup-manager/Programs.vue'
import Partners from '@/views/incubator-portal/startups/pages/startup-manager/Partners.vue'
import Mentors from '@/views/incubator-portal/startups/pages/startup-manager/Mentors.vue'
import About from '@/views/incubator-portal/startups/pages/startup-manager/About.vue'
import EMeet from '@/views/incubator-portal/startups/pages/startup-manager/Emeet.vue'
import Growth from '@/views/incubator-portal/startups/pages/startup-manager/Growth.vue'
import Feedback from '@/views/incubator-portal/startups/pages/startup-manager/Feedback.vue'
import Document from '@/views/incubator-portal/startups/pages/startup-manager/Document.vue'

export default {
  components: {
    EMeet,
    About,
    Programs,
    Partners,
    Requests,
    Milestones,
    Mentors,
    Growth,
    Feedback,
    Document,
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
  },
  data() {
    return {
      activeTab: 0,
      tabs: ['#About',
        // '#Agreements',
        '#EMeet',
        '#Programs',
        '#Milestones',
        '#Requests',
        '#Partners',
        '#Mentors',
        '#Growths',
        '#Feedbacks',
        '#Documents',
        '#Campaigns'],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
